<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <b-container class="content-section" v-show="showOrder">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <b-row>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Today Shipping</p>
                            <p>{{dashboard.shipping}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Today Assign</p>
                            <p>{{dashboard.assign}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>All Order</p>
                            <p>{{dashboard.all}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Completed</p>
                            <p>{{dashboard.complete}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dashboard',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                showOrderInfo: false,
                showOrder: true,
                dashboard: [],
                orders: []
            }
        },
        computed: {
            ...mapState({})
        },
         methods: {
            ...mapActions({
                dashboardAction: 'dashboardAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            orderInfoShow(order) {
                this.showOrder = false
                this.showOrderInfo = true
                this.orders = order
            },
            closeOrderInfo() {
                this.showOrder = true
                this.showOrderInfo = false
            },
        },
        async mounted() {
            this.isLoading = true
            this.dashboardDetail()
        }
    }
</script>

<style scoped lang="scss">
    .status-info {
        width: 100%;
        height: 100vh;
        background: hsl(210 11% 92% / 1);
        z-index: 9999;
        position: absolute;
        top: 52px;
    }
</style>